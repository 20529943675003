import { mediaQueries } from 'shared/breakpoints';
import styled from 'styled-components';
import { SectionWrapper } from 'styles/Typography.styled';

export const BottomHomeHeroWrapper = styled(SectionWrapper)`
    display: flex;
    justify-content: space-between;
    padding: 160px 0 0;

    ${mediaQueries.md} {
        padding: 160px 20px 0 20px;
    }

    ${mediaQueries.sm} {
        flex-direction: column;
        padding: 120px 20px 0 20px;
    }

    ${mediaQueries.xs} {
        padding: 48px 16px;
    }
`;
