import { EmptyState } from 'components/EmptyState/EmptyState';
import { navigate } from 'gatsby';
import React from 'react';
import { useTable } from 'react-table';
import { columns } from 'shared/columns';
import { TableWrapper } from 'styles/Table.styled';

interface HomeTableProps {
    data: any;
    investmentSlug: string;
}

export const HomeTable = ({ data, investmentSlug }: HomeTableProps) => {
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
        useTable({
            columns,
            data,
            initialState: {
                sortBy: [
                    {
                        id: 'flatStatus',
                        desc: true,
                    },
                ],
            },
        } as any);

    const firstPageRows = rows.slice(0, 6);

    if (!rows.length) {
        return (
            <EmptyState text="Przepraszamy, nie mamy już wolnych mieszkań w tej inwestycji. Sprawdź nasze inne inwestycje" />
        );
    }

    return (
        <TableWrapper>
            <table {...getTableProps()}>
                <thead>
                    {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                                <th {...column.getHeaderProps()}>
                                    {column.render('Header')}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {firstPageRows.map((row: any) => {
                        prepareRow(row);
                        return (
                            <tr
                                {...row.getRowProps()}
                                onClick={() =>
                                    navigate(
                                        '/' +
                                            investmentSlug +
                                            '/mieszkanie/' +
                                            row.original.investmentBuilding +
                                            '/' +
                                            row.original.flatNumber,
                                    )
                                }
                            >
                                {row.cells.map((cell: any) => {
                                    return (
                                        <td {...cell.getCellProps()}>
                                            {cell.render('Cell')}
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </TableWrapper>
    );
};
