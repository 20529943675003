import { mediaQueries } from 'shared/breakpoints';
import styled, { css } from 'styled-components';
import { HeroText } from 'styles/Typography.styled';
import { Swiper } from 'swiper/react';

export const FlatsWrapper = styled.div`
    align-items: center;
    background: #dbe5f0;
    display: flex;
    flex-direction: column;
    padding: 88px 136px;

    ${mediaQueries.md} {
        padding: 56px 16px;
    }
`;

export const SectionDescription = styled(HeroText)`
    margin: 24px 0 40px 0;
    max-width: 770px;
    text-align: center;
`;

export const SwiperWrapper = styled(Swiper)`
    display: none;
    width: calc(100vw - 32px);

    ${mediaQueries.sm} {
        display: block;
    }
`;

export const TableSectionWrapper = styled.div(
    ({ theme: { colors } }) =>
        css`
            margin-bottom: 56px;
            overflow-x: auto;
            width: 100%;

            .swiper-pagination-bullet {
                background: ${colors.primaryBlue300} !important;
            }

            .swiper-pagination-bullet-active {
                background: ${colors.primaryBlue500} !important;
            }

            .flat-pagination-div {
                display: none;
            }

            ${mediaQueries.sm} {
                display: block;
                margin-bottom: 28px;

                .flat-pagination-div {
                    display: flex;
                    justify-content: center;
                    margin-top: 16px;
                }
            }
        `,
);

export const TableWrapper = styled.div`
    display: block;

    ${mediaQueries.sm} {
        display: none;
    }
`;
