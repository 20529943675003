import { mediaQueries } from 'shared/breakpoints';
import styled from 'styled-components';
import {
    H2,
    SecondaryButton,
    SectionText,
    SectionWrapper,
} from 'styles/Typography.styled';

export const SectionTitle = styled(H2)`
    margin-bottom: 24px;
`;

export const GalleryLeftWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-right: 16px;
    width: 100%;

    ${mediaQueries.sm} {
        margin-right: 0;
    }
`;

export const GalleryRightWrapper = styled.div`
    margin-left: 16px;
    width: 100%;

    ${mediaQueries.sm} {
        display: none;
    }
`;

export const LeftImage = styled.img`
    margin-bottom: 52px;
    max-width: 570px;
    width: 100%;

    ${mediaQueries.sm} {
        display: none;
    }
`;

export const StyledSectionWrapper = styled(SectionWrapper)`
    padding: 180px 0;

    ${mediaQueries.md} {
        padding: 56px 16px;
    }
`;

export const RightImage = styled.img`
    width: 100%;
    max-width: 570px;

    &:first-of-type {
        width: 100%;
        margin-bottom: 28px;
    }
    &:last-of-type {
        width: 100%;
    }

    ${mediaQueries.sm} {
        display: none;
    }
`;

export const GalleryText = styled(SectionText)`
    margin: 24px 0 72px;
`;

export const StyledButton = styled(SecondaryButton)`
    ${mediaQueries.xs} {
        margin: 0 auto;
        max-width: 280px;
        width: 100%;
    }
`;
