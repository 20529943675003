import styled, { css } from 'styled-components';
import { H1, SectionWrapper } from 'styles/Typography.styled';
import Blocks from 'images/blocks.png';
import MobileBlocks from 'images/mobile-blocks.png';
import { mediaQueries } from 'shared/breakpoints';

export const HomeHeroWrapper = styled(SectionWrapper)`
    padding: 148px 0 180px;
    ${mediaQueries.sm} {
        padding: 148px 0 0;
    }
`;

export const TextWrapper = styled.div`
    max-width: 1005px;
    z-index: 2;

    ${mediaQueries.sm} {
        padding: 0 16px;
        text-align: center;
        width: 100%;
    }
`;

export const DescriptionWrapper = styled.div(
    ({ theme: { colors } }) => css`
        background-color: ${colors.primaryBlue50};
        display: grid;
        grid-gap: 42px;
        width: 500px;
        z-index: 1;

        ${mediaQueries.sm} {
            justify-items: center;
            width: 100%;
            padding-bottom: 32px;
        }
    `,
);

export const RightWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 96px;
    position: absolute;
    right: 0;
    top: 0;
    width: 705px;
    z-index: 1;
    ::after {
        background-image: url(${Blocks});
        bottom: 0;
        content: '';
        height: 265px;
        position: absolute;
        right: 0;
        width: 794px;
        ${mediaQueries.sm} {
            background-image: url(${MobileBlocks});
            bottom: unset;
            height: 100%;
            top: 0;
            width: 125px;
        }
    }
    ${mediaQueries.sm} {
        height: 460px;
        width: 100%;
    }
`;

export const Container = styled.div(
    ({ theme: { colors } }) => css`
        background-color: ${colors.primaryBlue50};
        display: flex;
        height: 653px;
        padding: 0 20px;
        position: relative;

        ${mediaQueries.sm} {
            align-items: flex-end;
            padding: 0;
            height: 100%;
        }
    `,
);

export const Title = styled(H1)(
    ({ theme: { colors } }) => css`
        background-color: ${colors.primaryBlue50};
        height: 194px;
        margin: 0;
        padding: 20px 30px 24px 0;
        width: 740px;
        z-index: 2;
        ${mediaQueries.sm} {
            height: auto;
            padding: 32px 16px 24px;
            width: 100%;
        }
    `,
);

export const Image = styled.img`
    height: 100%;
`;

export const InfoBox = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 56px;
`;

export const InfoWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;
